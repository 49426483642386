
import { array, arrayOf, shape, string } from 'vue-types';

const NAV_STATE_DEFAULT = 'state-default';
const NAV_STATE_COLLAPSED = 'state-collapsed';
const NAV_STATE_TRANSPARENT = 'state-transparent';

export default {
  inheritAttrs: false,
  props: {
    menuItemsLeft: array(),
    menuItemsRight: array(),
    news: shape({
      dateUpdated: string(),
      items: arrayOf(
        shape({
          id: string(),
          text: string(),
        }).loose,
      ),
    }).loose,
  },
  data() {
    return {
      state: NAV_STATE_TRANSPARENT,
      NAV_STATE_COLLAPSED,
    };
  },
  computed: {
    scrollDepth() {
      return this.$store.state.scroll.depth || 0;
    },
    productCount() {
      return this.$store.getters.cartTotalProductCount;
    },
    showingMenu() {
      return this.$store.state.showMenu;
    },
    hideNav() {
      return this.$store.getters.hideHeader;
    },
    computedNavItemsLeft() {
      return this.menuItemsLeft.map(({ id, page, label }) => {
        const { url, title } = this.$first(page) || {};
        return {
          id,
          to: url ? this.$pathFromUrl(url) : '#',
          label: label || title || '',
        };
      });
    },
    computedNavItemsRight() {
      return this.menuItemsRight.map(({ id, page, label }) => {
        const { url, title } = this.$first(page) || {};
        return {
          id,
          to: url ? this.$pathFromUrl(url) : '#',
          label: label || title || '',
        };
      });
    },
    computedNavItems() {
      return [...this.computedNavItemsLeft, ...this.computedNavItemsRight];
    },
  },
  watch: {
    scrollDepth(newVal, oldVal) {
      if (this.scrollDepth <= 0) {
        this.state = NAV_STATE_TRANSPARENT;
      } else if (!this.hideNav && !this.showingMenu && newVal > oldVal) {
        this.state = NAV_STATE_COLLAPSED;
      } else {
        this.state = NAV_STATE_DEFAULT;
      }
    },
  },
  methods: {
    onMenuButtonClick() {
      this.$store.commit('toggleMenu');
    },
  },
};
